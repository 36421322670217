<template>
  <Page class="settings" title="Configurações" type="secondary">
    <form>
      <div class="form-item">
        <photo class="photo" v-model="photo" displayClearBtn/>
      </div>
      <div class="form-item">
        <text-input label="Nome" autocapitalize="words" placeholder="Nome completo" v-model.trim="name"/>
      </div>
      <div class="form-item">
        <text-input label="Matrícula" type="number" placeholder="7 dígitos, recomendo começar com 21..." v-model.number="id"/>
      </div>
      <div class="form-item">
        <text-input label="Curso" v-model.trim="curse"/>
      </div>
      <div class="form-item metrics">
        <text-input label="C. em curso" type="number" v-model.number="inCurseCredits"/>
        <text-input label="PMG" type="number" v-model.number="pmg"/>
        <text-input label="C. cursados" type="number" v-model.number="cursedCredits"/>
      </div>
    </form>
  </Page>
</template>

<script>
import Page from '@/components/Page.vue'
import TextInput from '@/components/TextInput.vue'
import Photo from '@/components/Photo.vue'

export default {
  name: 'Settings',

  components: {
    Page,
    TextInput,
    Photo
  },

  computed: {
    name: {
      get () {
        return this.$store.state.name
      },
      set (value) {
        this.$store.commit('updateName', value)
      }
    },
    id: {
      get () {
        return this.$store.state.id
      },
      set (value) {
        this.$store.commit('updateId', value)
      }
    },
    curse: {
      get () {
        return this.$store.state.curse
      },
      set (value) {
        this.$store.commit('updateCurse', value)
      }
    },
    inCurseCredits: {
      get () {
        return this.$store.state.inCurseCredits
      },
      set (value) {
        this.$store.commit('updateInCurseCredits', value)
      }
    },
    pmg: {
      get () {
        return this.$store.state.pmg
      },
      set (value) {
        this.$store.commit('updatePmg', value)
      }
    },
    cursedCredits: {
      get () {
        return this.$store.state.cursedCredits
      },
      set (value) {
        this.$store.commit('updateCursedCredits', value)
      }
    },
    photo: {
      get () {
        return this.$store.state.photo
      },
      set (value) {
        this.$store.commit('updatePhoto', value)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.settings {
  /deep/ .content {
    padding: 10px;
  }

  form {
    .form-item {
      display: flex;
      margin-bottom: 20px;

      > div {
        flex: 1;
        margin-right: 5px;
      }
    }
  }

  .metrics /deep/ input {
    text-align: center;
  }

  .photo {
    border-radius: 10px;
    height: 200px;
    width: 200px;
    flex: unset !important;
    margin: 0 auto !important;

    /deep/ .controls {
      bottom: 10px;
      right: 10px;
    }
  }
}
</style>
