<template>
  <div class="text-input">
    <label>{{label}}</label>
    <input v-bind="$attrs" v-on="listeners" ref="input" autocomplete="off">
  </div>
</template>

<script>
export default {
  name: 'TextInput',
  inheritAttrs: false,

  props: {
    label: {
      type: String,
      default: null,
      required: false
    }
  },

  methods: {
    focus () {
      this.$refs.input.focus()
    }
  },

  computed: {
    listeners () {
      return {
        ...this.$listeners,
        input: e => {
          this.$emit('input', e.target.value)
          this.passwordVisible = false
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.text-input {
  label {
    color: #555;
    font-family: 'Roboto Condensed', sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    color: var(--main-color);
    font-size: 11pt;
  }

  input {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    background: transparent;
    font-size: 12pt;
    color: #555;
    outline: none;
    font-family: inherit;
    width: 100%;
    padding: 5px 0;
    transition: all 200ms;

    &:focus {
      border-color: var(--main-color);
    }

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    &::-webkit-calendar-picker-indicator {
      opacity: 0.5;
      filter: invert(1);
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }

    &:disabled {
      opacity: 0.5;
    }
  }
}
</style>
