<template>
  <div class="page">
    <header-bar :title="title">
      <slot name="header-bar-controls"/>

      <template #action-btn>
        <header-bar-control v-if="type === 'primary'" icon="fal fa-bars"/>
        <header-bar-control v-else icon="fal fa-arrow-left" @click="$router.back()"/>
      </template>
    </header-bar>

    <div class="content">
      <slot/>
    </div>
  </div>
</template>

<script>
import HeaderBar from '@/components/HeaderBar.vue'
import HeaderBarControl from '@/components/HeaderBarControl.vue'

export default {
  name: 'Page',

  props: {
    title: String,
    type: {
      type: String,
      default: 'primary'
    }
  },

  components: {
    HeaderBar,
    HeaderBarControl
  }
}
</script>

<style lang="less" scoped>
.page {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f1f1f1;
  overflow: auto;
}
</style>
