<template>
  <div class="overlay">
    <div class="sidebar">
      <div class="header">
        <div class="photo-container">
          <photo v-model="photo"/>
        </div>
        <div>
          <div class="name">{{name}}</div>
          <div class="id">{{id}}</div>
        </div>
      </div>
      <div class="menu">
        <div class="menu-item">
          <i class="fas fa-book"></i> Matrícula
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Photo from '@/components/Photo.vue'
import { mapState } from 'vuex'

export default {
  name: 'Sidebar',

  components: {
    Photo
  },

  computed: mapState([
    'name',
    'id',
    'photo'
  ])
}
</script>

<style lang="less" scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  background: rgba(0, 0, 0, 0);
  transition: background 0.5s;
  display: none;

  &.show {
    background: rgba(0, 0, 0, 0.3);

    .sidebar {
      transform: translateX(0);
    }
  }

  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 300px;
    background: #fff;
    transition: transform 0.5s;
    transform: translateX(-100%);

    .header {
      background: #eee;
      padding: 15px;
      display: flex;

      > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .photo-container {
        flex: 0 0 80px;

        .photo {
          width: 65px;
          height: 65px;
          margin: 0;
        }
      }

      .name {
        font-size: 11pt;
        font-weight: 500;
        margin-bottom: 5px;
        color: #444;
      }

      .id {
        font-size: 10pt;
        color: var(--main-color);
      }
    }

    .menu {
      padding: 15px 35px;
    }
  }
}
</style>
