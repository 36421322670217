<template>
  <div class="header-bar">
    <div class="btn-action">
      <slot name="action-btn"/>
    </div>
    <div>{{title}}</div>
    <div class="controls">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderBar',

  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="less" scoped>
.header-bar {
  display: flex;
  align-items: center;
  height: 50px;
  background-color: var(--main-color);
  color: #fff;
  font-size: 15pt;
  box-shadow: 0 2px 1px #00000024;
  position: sticky;
  top: 0;
  z-index: 1;

  > div {
    flex: 1;
  }

  .btn-action {
    flex: 0 0 70px;
    text-align: center;
    margin-right: 20px;

    /deep/ .header-bar-control button {
      display: inline-block;
    }
  }

  .controls {
    text-align: right;
    padding-right: 15px;
    display: flex;
    flex-grow: 0;
  }
}
</style>
