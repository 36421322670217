<template>
  <Page class="profile" title="Perfil" type="primary">
    <template #header-bar-controls>
      <header-bar-control icon="fas fa-cog" @click="$router.push('/settings')"/>
    </template>

    <div class="header">
      <div class="photo-container">
        <photo v-model="photo" displayEditBtn/>
      </div>
      <div class="student-info-container">
        <div class="name">{{name.toUpperCase()}}</div>
        <div class="id">{{id}}</div>
      </div>
      <div class="course">{{curse}}</div>
      <div class="metrics-container">
        <div class="metrics-value">
          <div>{{inCurseCredits}}</div>
          <div>{{pmg}}</div>
          <div>{{cursedCredits}}</div>
        </div>
        <div class="metrics-label">
          <div>CRÉDITOS EM CURSO</div>
          <div>PMG</div>
          <div>CRÉDITOS CURSADOS</div>
        </div>
      </div>
    </div>
    <div class="body">
      <div class="title">AVISOS</div>
      <div class="feed">
        <div class="item" v-for="item in feed" :key="Math.random(item)">
          <div class="title">Unifor - {{item.title}} <span class="date">{{item.date}}</span></div>
          <div class="content">{{item.body}}</div>
        </div>
      </div>
    </div>
  </Page>
</template>

<script>
import Page from '@/components/Page.vue'
import HeaderBarControl from '@/components/HeaderBarControl.vue'
import Photo from '@/components/Photo.vue'
import { mapState } from 'vuex'

export default {
  name: 'Home',

  components: {
    Page,
    HeaderBarControl,
    Photo
  },

  methods: {
    generateFeed () {
      const feedVariants = [
        {
          title: 'Frequência',
          body: 'Olá, {userName}. Sentimos sua falta na disciplina G{$rand,200,400}. Gostaríamos de ajudá-lo no que for possível! Para isso, o nosso Tutorial acadêmico irá contatá-lo em breve.'
        },
        {
          title: 'Frequência',
          body: 'A frequência de "{month}" da turma G{$rand,100,500}-{$rand,30,70} está disponível para consulta.'
        },
        {
          title: 'Nota',
          body: 'A média de AV{$rand,2,6} da turma G{$rand,100,500}-{$rand,30,70} está disponível para consulta.'
        }
      ]

      const date = new Date()
      const rand = (min, max) => Math.floor(Math.random() * (max - min)) + min
      const parseBody = body => {
        let parsed = body.replaceAll('{userName}', this.name)
        parsed = parsed.replaceAll('{month}', new Date().toLocaleString('pt-BR', { month: 'long' }))

        return parsed.replace(/{(\$rand.+?)}/g, (_a, b) => {
          const params = b.split(',')
          return rand(Number(params[1]), Number(params[2]))
        })
      }

      for (let i = 0; i < 50; i++) {
        const { title, body } = feedVariants[rand(0, 3)]

        date.setDate(date.getDate() - i)

        this.feed.push({
          title,
          body: parseBody(body),
          date: date.toLocaleDateString()
        })
      }
    }
  },

  computed: {
    ...mapState([
      'name',
      'id',
      'curse',
      'inCurseCredits',
      'pmg',
      'cursedCredits'
    ]),
    photo: {
      get () {
        return this.$store.state.photo
      },
      set (value) {
        this.$store.commit('updatePhoto', value)
      }
    }
  },

  data () {
    return {
      feed: []
    }
  },

  mounted () {
    this.feed = []

    this.generateFeed()
  }
}
</script>

<style lang="less" scoped>
.profile {
  .header {
    background: url(../assets/profile-bg.png) no-repeat top fixed;
    background-size: 125%;
    padding: 25px;
    color: #fff;
    text-align: center;

    .student-info-container {
      padding: 10px 0;

      .name {
        font-size: 14pt;
      }

      .id {
        font-size: 10pt;
      }
    }

    .course {
      font-size: 11pt;
      padding: 15px 0;
    }

    .metrics-container {
      > div {
        display: flex;

        > div {
          flex: 1;
          padding: 0 10px;
        }
      }

      .metrics-value {
        font-size: 16pt;
        font-weight: 600;
      }

      .metrics-label {
        padding-top: 5px;
        font-size: 9pt;
      }
    }
  }

  .body {
    font-size: 11pt;

    > .title {
      padding: 7px;
      background: #eee;
      color: #999;
      font-size: 10pt;
    }

    .feed {
      .item {
        padding: 15px 10px;
        border-bottom: 1px solid #ddd;
        color: #555;

        .title {
          color: rgb(53, 149, 220);
          font-weight: 600;
          margin-bottom: 5px;
          font-size: 11pt;

          .date {
            color: #aaa;
            font-weight: 500;
            font-size: 8pt;
            float: right;
          }
        }
      }
    }
  }
}
</style>
