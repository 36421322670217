import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'

const vuexPersist = new VuexPersist({
  key: 'unifor-app',
  storage: window.localStorage
})

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    name: 'Foo Bar',
    id: '1234567',
    curse: 'Lorem Ipsum',
    inCurseCredits: 0,
    pmg: 0,
    cursedCredits: 0,
    photo: null
  },
  mutations: {
    updateName (state, value) {
      state.name = value
    },
    updateId (state, value) {
      state.id = value
    },
    updateCurse (state, value) {
      state.curse = value
    },
    updateInCurseCredits (state, value) {
      state.inCurseCredits = value
    },
    updatePmg (state, value) {
      state.pmg = value
    },
    updateCursedCredits (state, value) {
      state.cursedCredits = value
    },
    updatePhoto (state, value) {
      state.photo = value
    }
  },
  plugins: [
    vuexPersist.plugin
  ]
})
