<template>
  <div class="photo" :style="{ backgroundImage: parsedValue }">
    <div class="controls">
      <button v-if="displayClearBtn" type="button" class="photo-edit" @click="$emit('input', null)">
        <i class="fas fa-trash"></i>
      </button>
      <button v-show="displayEditBtn" type="button" class="photo-edit" @click="openFilePicker">
        <i class="fas fa-pencil"></i>
        <input type="file" ref="upload" accept=".jpeg,.jpg,.png">
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Photo',

  props: {
    value: String,
    displayClearBtn: {
      type: Boolean,
      default: false
    },
    displayEditBtn: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    openFilePicker () {
      this.$refs.upload.click()
    }
  },

  computed: {
    parsedValue () {
      return this.value
        ? `url(${this.value})`
        : undefined
    }
  },

  mounted () {
    this.$refs.upload.onchange = e => {
      const file = e.target.files[0]
      const fileReader = new FileReader()

      fileReader.onload = () => {
        this.$emit('input', fileReader.result)
      }

      fileReader.readAsDataURL(file)
    }
  }
}
</script>

<style lang="less" scoped>
.photo {
  background: url(../assets/no-photo.jpg) center center;
  background-size: cover;
  width: 150px;
  height: 150px;
  border-radius: 100%;
  margin: 0 auto 10px auto;
  position: relative;

  .controls {
    position: absolute;
    bottom: 0;
    right: 0;

    button {
      width: 45px;
      height: 45px;
      border: none;
      margin-left: 10px;
      border-radius: 100%;
      background: var(--main-color);
      box-shadow: 1px 2px 4px #0000003b;
      font-size: 15pt;
      padding: 13px;
      color: #fff;

      input {
        display: none;
      }
    }
  }
}
</style>
