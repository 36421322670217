<template>
  <div id="app">
    <sidebar/>

    <transition name="page-transition">
      <router-view/>
    </transition>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue'

export default {
  name: 'App',

  components: {
    Sidebar
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap');

:root {
  --main-color: rgb(2, 119, 189);
  --status-bar-color: rgb(2, 89, 142);
  --contrast-color: #fff;
  --text-color: #585858;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none;
  cursor: default;
  -webkit-tap-highlight-color: transparent;
}

body, html {
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  overscroll-behavior: none;
  touch-action: none;
}

::-webkit-scrollbar {
  display: none;
}
::-webkit-scrollbar-thumb {
  display: none;
}
</style>

<style lang="less" scoped>
.page-transition-enter-active {
  animation: page-transition 0.5s reverse ease-in-out;
}

.page-transition-leave-active {
  animation: page-transition 0.5s ease-in-out;
}

@keyframes page-transition {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}
</style>
