<template>
  <div class="header-bar-control">
    <button v-if="type === 'icon'" class="btn-icon" @click="$emit('click', $event)">
      <i :class="icon"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: 'HeaderBarControl',

  props: {
    icon: String,
    label: String
  },

  computed: {
    type () {
      return this.icon !== undefined ? 'icon' : 'label'
    }
  }
}
</script>

<style lang="less" scoped>
.header-bar-control {
  button {
    padding: 5px;
    background: transparent;
    color: #fff;
    border: none;
    color: #fff;

    &.btn-icon {
      font-size: 15pt;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      align-content: center;

      > i {
        flex: 1;
      }
    }

    &.btn-label {
      font-size: 12pt;
      text-transform: uppercase;
      font-weight: 600;
      border-radius: 5px;
      padding: 10px 15px;
    }
  }
}
</style>
